import React from "react";
import { useTranslation } from "react-i18next";
import Style from "./GiftcardSummary.module.scss";

const GiftCardSummary = ({ data }) => {
  const { t } = useTranslation(["common"]);
  return (
    <div className={Style.gift_card_summary}>
      <div className={Style.order_summary}>
        <h4 className={`${Style.order_summary_title} mb-0`}>{t("Summary")}</h4>
        <ul className={Style.order_summary_list}>
          <li className={Style.order_summary_list_item}>
            <p className={`${Style.order_label} mb-0`}>{t("Orginal_amount")}</p>
            <p className={`${Style.order_price} mb-0`}>
              {" "}
              {t("DHS")} {parseInt(data?.orginal_amount).toFixed(2)}
            </p>
          </li>
          <li className={Style.order_summary_list_item}>
            <p className={`${Style.order_label} mb-0`}>{t("Bonus_amount")}</p>
            <p className={`${Style.order_price} ${Style.bonus} mb-0`}>
              {t("DHS")} {data?.bonus_amount}
            </p>
          </li>
          <li className={`${Style.order_summary_list_item} ${Style.total}`}>
            <p className={`${Style.order_label} mb-0`}>{t("You_Pay")}</p>
            <p className={`${Style.order_price} ${Style.bonus} mb-0`}>
              {t("DHS")} {parseInt(data?.orginal_amount).toFixed(2)}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GiftCardSummary;
