import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAxiosInstance } from "../apis";
import { getAuthToken } from "../store/slices/authTokenSlice";

export const useToken = () => {
  const [basicData, setBasicData] = useState();

  const dispatch = useDispatch();

  const getTokenFromApi = () => {
    dispatch(getAuthToken());
  };

  const getBasicDetails = async (lang) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`basic-settings?language=${lang}`);
      setBasicData(response?.data?.data);
    } catch (error) {
      return error.response.data;
    }
  };

  return {
    getTokenFromApi,
    getBasicDetails,
    basicData,
  };
};
