import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Style from "./Strip.module.scss";
import giftIcon from "./gift.svg";

const Strip = () => {
  const { t } = useTranslation(["common"]);
  const lang = localStorage.getItem("language_type");

  useEffect(() => {
    if (lang === "ar") {
      document.getElementsByClassName("stripSwitcher")[0].setAttribute("dir", "rtl");
    } else if (lang === "en") {
      document.getElementsByClassName("stripSwitcher")[0].setAttribute("dir", "ltr");
    } else {
      localStorage.setItem("language_type", "en");
    }
  }, []);

  return (
    <div className={`${Style.strip_wrapper} stripSwitcher`}>
      <img src={giftIcon} alt="gift-icon" />
      <p className={Style.strip_text}>
        <a
          href="https://www.ikea.com/ae/en/customer-service/self-serve-pub0614f790#checkGiftCardBalanceCard"
          target="_blank"
        >
          {t("header_Strip")}
        </a>
      </p>
    </div>
  );
};

export default Strip;
