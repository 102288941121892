import React, { Suspense, lazy } from "react";
import "../styles/common.scss";
import { Routes, Route } from "react-router-dom";
import GiftCardsPage from "../pages/GiftCards";
import CheckoutPage from "../pages/Checkout";
import TransactionPage from "../pages/Transaction";
import CommonLayout from "./Layouts/CommonLayout";

const App = () => {
  return (
    <Suspense fallback={null}>
      <CommonLayout>
        <Routes>
          <Route path="/" element={<GiftCardsPage />} />
          <Route path="/:lang" element={<GiftCardsPage />} />
          <Route path="/checkout/:slug/:lang" element={<CheckoutPage />} />
          <Route path="/order-summary/:orderId/:langTrans" element={<TransactionPage />} />
        </Routes>
      </CommonLayout>
    </Suspense>
  );
};

export default App;
