import React from "react";
import { useTranslation } from "react-i18next";
import Style from "./CheckoutFor.module.scss";
import moment from "moment";

const CheckoutFor = ({ data }) => {
  const { t } = useTranslation(["common"]);
  return (
    <>
      <div className={Style.card_block}>
        {data?.gift_card?.gift_card_full_url && (
          <figure className={Style.card_block_img}>
            <img src={data?.gift_card?.gift_card_full_url} alt="Gift Card" />
          </figure>
        )}
        <div className={Style.text_block}>
          <div className={Style.text_block_amount}>
            {data?.amount && (
              <>
                {t("DHS")} {data?.amount !== undefined && data?.amount}
              </>
            )}
          </div>
          <p className={Style.receipient_name}>{data?.name}</p>
        </div>
      </div>
      <div className={Style.gift_to}>
        <p className={`${Style.gift_to_text} mb-0`}>
          {t(!data?.sender_name ? "Sender_Name" : "Recipient_Name2")}:&nbsp;
          <span>{data?.name}</span>
        </p>
        <p className={`${Style.gift_to_text} mb-0`}>
          {t(!data?.sender_name ? "Sender_Email" : "Recipient_mail2")}:&nbsp;
          <span>{data?.email}</span>
        </p>
        <p className={`${Style.gift_to_text} mb-0`}>
          {t(!data?.sender_name ? "Sender_Mobile_Number_Sender" : "Recipient_Number2")}
          :&nbsp;<span>{data?.mobile}</span>
        </p>
        {data?.sender_name && (
          <p className={`${Style.gift_to_text} mb-0`}>
            {t("Senders_Name_check")}:&nbsp;<span>{data?.sender_name}</span>
          </p>
        )}
        <p className={`${Style.gift_to_text} mb-0`}>
          {t("Arrive_ASAP")}:&nbsp;
          <span>
            {new Date().toDateString() === new Date(data?.date).toDateString()
              ? t("Now")
              : moment(data?.date, "yyyy/MM/DD").format("DD/MM/yyyy")}
          </span>
        </p>
      </div>
    </>
  );
};

export default CheckoutFor;
