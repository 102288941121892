import React from "react";
import { useTranslation } from "react-i18next";
import Style from "./VatAlert.module.scss";

const VatAlert = () => {
  const { t } = useTranslation(["common"]);

  return (
    <div className={Style.order_summary}>
      <div className={Style.order_summary_sec}>
        <div className={Style.order_summary_icon}></div>
        <div className={Style.order_summary_content}>
          <h4 className={Style.summary_title}> {t("Alert_Title")}!</h4>
          <p className={`${Style.summary_desc} mb-0`}>{t("Alert_Content")}</p>
        </div>
      </div>
    </div>
  );
};

export default VatAlert;
