import React, { useState, useEffect } from "react";
import Footer from "../Footer";
import Menu from "../Menu";
import i18n from "../../../i18n";
import Strip from "../../Strip";

const CommonLayout = ({ children }) => {
  let modAppLocation = window?.location?.href?.split("?")[1]?.split("=");
  const [modeCokkie, setModeCookie] = useState();

  function getCookie(name) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [k, v] = el.split("=");
      cookie[k.trim()] = v;
    });
    return cookie[name];
  }

  function setCookie(cName, cValue, expDays) {
    let date = new Date();
    date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  }

  useEffect(() => {
    if (modAppLocation?.[0] == "mode" && getCookie("mode") == undefined) {
      setCookie(modAppLocation?.[0], modAppLocation?.[1], 1);
      setModeCookie(modAppLocation?.[1]);
    } else {
      setModeCookie(getCookie("mode"));
    }
  }, []);

  return (
    <>
      {modeCokkie !== "app" && <Menu />}
      <div className="switcher">{children}</div>
      {modeCokkie !== "app" && <Footer />}
      {modeCokkie !== "app" && <Strip />}
    </>
  );
};

export default CommonLayout;
