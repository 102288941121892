import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Style from "./GiftCardsPersonalise.module.scss";
import SomeoneSpecial from "./SomeoneSpecial";
import GiftCardsDelivery from "../GiftCardsDelivery";
import GiftCardSummary from "../GiftcardSummary";

const GiftCardsPersonalise = ({
  tab,
  loader,
  setTab,
  formik,
  basicData,
  responseError,
}) => {
  
  const { t } = useTranslation(["common"]);

  return (
    <section className={Style.gift_cards_personalise}>
      <h1 className={`${Style.gift_cards_title} mb-0`}>{t("PERSONALISE_GIFT_CARD")}</h1>
      <div className={Style.form_wrapper}>
        <div className="row justify-content-lg-between">
          <div className="col-12 col-lg-6 col-xxl-5">
            <div className={Style.radio_wrap}>
              <div class={`form-check form-check-inline ${Style.form_check}`}>
                <input
                  class="form-check-input"
                  type="radio"
                  name="radioType"
                  id="inlineRadio1"
                  value="1"
                  defaultChecked={formik.values.radioType === "1"}
                  onClick={() => {
                    setTab("1");

                    formik.setFieldValue("radioType", "1");
                  }}
                />
                <label class="form-check-label" for="inlineRadio1">
                  {t("Myself")}
                </label>
              </div>
              <div class={`form-check form-check-inline ${Style.form_check}`}>
                <input
                  class="form-check-input"
                  type="radio"
                  name="radioType"
                  id="inlineRadio2"
                  value="2"
                  defaultChecked={formik.values.radioType === "2"}
                  onClick={() => {
                    setTab("2");
                    formik.setFieldValue("radioType", "2");
                  }}
                />
                <label class="form-check-label" for="inlineRadio2">
                  {t("SomeOne")}
                </label>
              </div>
            </div>
            <div className={Style.tab_sec}>
              {tab === "1" ? (
                <div>
                  <div className={Style.input_wrapper}>
                    <Form.Group className="form-group text-start">
                      <Form.Label>{t("Name")}</Form.Label>
                      <Form.Control
                        type="text"
                        id="senderName"
                        placeholder={t("Full_Name")}
                        onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        value={formik.values.senderName}
                        name="senderName"
                      />
                    </Form.Group>
                    {formik.touched.senderName && formik.errors.senderName ? (
                      <div className={`${Style.error_msg} formikError`}>
                        {formik.errors.senderName}
                      </div>
                    ) : null}
                  </div>
                  <div className={Style.input_wrapper}>
                    <Form.Group className="form-group text-start">
                      <Form.Label>{t("Email")}</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t("E_mail")}
                        id="senderEmail"
                        name="senderEmail"
                        onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        value={formik.values.senderEmail}
                      />
                    </Form.Group>
                    {formik.touched.senderEmail && formik.errors.senderEmail ? (
                      <div className={`${Style.error_msg} formikError`}>
                        {formik.errors.senderEmail}
                      </div>
                    ) : null}
                  </div>
                  <div className={Style.input_wrapper}>
                    <Form.Group className="form-group text-start ">
                      <Form.Label>{t("Mobile_Number_Sender")}</Form.Label>
                      <Form.Control
                        type="text"
                        id="senderMobile"
                        placeholder={t("Mobile_Number")}
                        onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        value={formik.values.senderMobile}
                        name="senderMobile"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>
                    {formik.touched.senderMobile && formik.errors.senderMobile ? (
                      <div className={`${Style.error_msg} formikError`}>
                        {formik.errors.senderMobile}
                      </div>
                    ) : null}
                    {responseError && (
                      <p className={`${Style.error_msg} formikError`}>{t("mobile_error")}</p>
                    )}
                  </div>
                </div>
              ) : (
                <SomeoneSpecial
                  tab={tab}
                  setTab={setTab}
                  formik={formik}
                  responseError={responseError}
                />
              )}
            </div>
            <GiftCardsDelivery formik={formik} loader={loader} responseError={responseError} />
          </div>
          <div className="col-12 col-lg-6 col-xxl-5">
            <GiftCardSummary basicData={basicData} formik={formik} />
            <div className={Style.btn_wrapper}>
              <button
                // variant="primary"
                type="submit"
                className={
                  loader
                    ? ` ${Style.add_cart_btn} loading custom_btn`
                    : `${Style.add_cart_btn} custom_btn`
                }
              >
                {t("PROCEED_TO_CHECKOUT")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GiftCardsPersonalise;
